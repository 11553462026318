// @ is an alias to /src
import { postUserLogin } from "@/service/userManagement/index";
import router from "../../utils/router";
export default {
  name: "login",
  //副菜单
  data() {
    return {
      form: this.$form.createForm(this),
      login: {
        userName: "",
        password: ""
      },
    };
  },
  methods: {
    //提交登录信息
    handlePostLogin(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          let { username, password } = values;
          this.login.userName = username;
          sessionStorage.setItem('loginName',username);
          this.login.password = password;
          postUserLogin(this.login).then(
            res => {
              if (!res.message) {
                // console.log('res.data',res.data)
                sessionStorage.setItem('password',this.login.password)
                sessionStorage.setItem('eName', res.data.eName);
                sessionStorage.setItem('userName', res.data.nickName);
                sessionStorage.setItem('userId', res.data.userId);
                sessionStorage.setItem('userPhone', res.data.userPhone);
                sessionStorage.setItem('unitId', res.data.unitId);
                sessionStorage.setItem('token', JSON.stringify(res.data.token));
                sessionStorage.setItem('sessionId', JSON.stringify(res.data.sessionId));
                sessionStorage.setItem('eId', res.data.eId);
                let mobi = this.isPc();
                if (mobi) {
                  this.$router.push({ path: '/phoneVehicleStatus' });
                } else {
                  this.$router.push({ path: '/' });
                  this.handleFormFieldsReset(this.form);
                }
              } else {
                this.$message.error("登录失败，原因：用户名或密码错误");
              }
            },
            err => {
              console.log("err", err);
              this.$message.error("错误" + err);
            }
          );
        }
      });
    },
    isPc() {
      let is_mobi = navigator.userAgent.toLowerCase().match(/(ipod|ipad|iphone|android|coolpad|mmp|smartphone|midp|wap|xoom|symbian|j2me|blackberry|wince)/i) != null;
      return is_mobi;
    },
    phoneStyle() {
      $('.logW').css({ 'fontSize': '30px', 'height': '80px' });
      $('.containe').css({ 'height': '400px' });
      $('.inner').css({ 'flexDirection': 'column', 'width': '100vw', 'justifyContent': 'center', 'height': '100%' });
      $('.formStyle').css({ 'width': '100%' });
      $('.ant-form-item-control-wrapper').css({ 'width': '100%' });
      $('.ant-form-item-no-colon').css({ 'padding': '20px 0px', 'boxSizing': 'border-box' });
      $('.login-button').css({ 'width': '100%', 'paddingTop': '20px', 'boxSizing': 'border-box' });
      $('.login-button button').css({ 'width': '320px' });
    },
    getBg() {
      let num = parseInt(10 * Math.random() + 1);
      let bgImg = "";
      if (num === 1) {
        bgImg = require("../../assets/images/bg2.jpg")
      } else if (num === 2) {
        bgImg = require("../../assets/images/bg3.jpg")
      } else if (num === 3) {
        bgImg = require("../../assets/images/bg4.jpg")
      } else if (num === 4) {
        bgImg = require("../../assets/images/bg5.jpg")
      } else if (num === 5) {
        bgImg = require("../../assets/images/bg6.jpg")
      } else if (num === 6) {
        bgImg = require("../../assets/images/bg7.jpg")
      } else if (num === 7) {
        bgImg = require( "../../assets/images/bg8.jpg")
      } else if (num === 8) {
        bgImg = require( "../../assets/images/bg9.jpg")
      } else if (num === 9) {
        bgImg = require("../../assets/images/bg6.jpg")
      } else if (num === 10) {
        bgImg = require("../../assets/images/bg1.jpg")
      }
      document.body.style.backgroundImage = "url(" + bgImg + ")";
      document.documentElement.style.backgroundImage = "url(" + bgImg + ")";
      document.body.style.backgroundRepeat = "no-repeat";
      document.body.style.backgroundSize = "cover";
      document.body.style.backgroundPosition = " center center";
      document.documentElement.style.backgroundRepeat = "no-repeat";
      document.documentElement.style.backgroundSize = "cover";
      document.documentElement.style.backgroundPosition = " center center";
      document.body.style.backgroundRepeat = "no-repeat";
      document.body.style.backgroundSize = "cover";
      document.body.style.backgroundPosition = " center center";
      document.documentElement.style.backgroundRepeat = "no-repeat";
      document.documentElement.style.backgroundSize = "cover";
      document.documentElement.style.backgroundPosition = " center center";
    }
  },
  mounted() {
    this.getBg();
    let phone = this.isPc();
    if (phone) {
      this.phoneStyle();
    }
    this.form.setFieldsValue({
      username: "",
      password: ""
    });
  }
};
